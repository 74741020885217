<template>
  <b-container>

    <div v-if="loading">
      <b-row>
        <b-col v-for="item of itemsLoading" :key="item" sm="3">
          <b-card no-body>
            <div class="text-center pt-1">
              <b-skeleton size="50%" align="center" />
            </div>
            <div class="d-flex justify-content-between mx-0 text-center ">
              <div class="border-top border-right d-flex align-items-between flex-column py-1 col-4">
                <b-skeleton />
                <h4>
                  <b-skeleton />
                </h4>
              </div>
              <div class="border-top border-right d-flex align-items-between flex-column py-1 col-4">
                <b-skeleton />
                <h4>
                  <b-skeleton />

                </h4>
              </div>
              <div class="border-top  d-flex align-items-between flex-column py-1 col-4">
                <b-skeleton />
                <h4>
                  <b-skeleton />
                </h4>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <div v-if="!loading && !isShowItems">
      <b-row>
        <b-col v-for="item of items" :key="item.data" sm="3">
          <b-card no-body class="cursor-pointer" @click="getMediasByDay(item)">
            <div class="text-center pt-1">
              <h2>{{ item.data | moment('DD/MM/YYYY') }}</h2>
            </div>
            <div class="d-flex justify-content-between mx-0 text-center ">
              <div class="border-top border-right d-flex align-items-between flex-column py-1 col-4">
                Audios
                <h4>{{ item.audios }}</h4>
              </div>
              <div class="border-top border-right d-flex align-items-between flex-column py-1 col-4">
                Capturas
                <h4>{{ item.screenshots }}</h4>
              </div>
              <div class="border-top  d-flex align-items-between flex-column py-1 col-4">
                Camera
                <h4>{{ item.pictures }}</h4>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>

    </div>
    <b-card v-if="isShowItems" no-body>
      <b-card-header class="p-1">
        <h4>
          <feather-icon class="cursor-pointer" icon="ArrowLeftIcon" @click="showDates" />
          Items do Dia {{ this.currentDate.data | moment('DD/MM/YYYY') }}
        </h4>
      </b-card-header>
      <b-row>
        <b-col>
          <Grid
            :fields="fields"
            :items="medias"
            edit-button-label="edit"
            new-button-label="new"
            :show-search="false"
            @onChangePage="getItems"
          >
            <template #search>
              <b-row align-v="center">
                <b-col
                  md="3"
                >
                  <i2-form-vue-select
                    v-model="search.type"
                    :options="mediaTypes"
                    label="Tipo da captura"
                    placeholder="Tipo de Media"
                    name="media_type"
                  />
                </b-col>
                <b-col
                  sm="6"
                  md="2"
                >
                  <b-button
                    variant="secondary"
                    type="button"
                    @click="getItems"
                  >
                    <FeatherIcon icon="SearchIcon" />
                    <span
                      class="text-nowrap"
                    />
                  </b-button>
                </b-col>
              </b-row>
            </template>
            <template #cell(created_at)="data">
              {{ data.item.created_at | moment('DD/MM/YYYY HH:mm:ss') }}<br>
            </template>
            <template #cell(data)="data">
              <audio v-if="data.item.type === 'audio'" controls>
                <source :src="getImageUrl(data.item)">
              </audio>
              <viewer v-if="data.item.type === 'image'" :images="[getImageUrl(data.item)]">
                <b-img height="200" :src="getImageUrl(data.item)" class="cursor-pointer" />
              </viewer>
              <viewer v-if="data.item.type === 'screenshot'" :images="[getImageUrl(data.item)]">
                <b-img height="200" :src="getImageUrl(data.item)" class="cursor-pointer" />
              </viewer>
            </template>
          </Grid>
        </b-col>
      </b-row>
    </b-card>
  </b-container>
</template>
<script>
import { BSkeleton } from 'bootstrap-vue'
import 'viewerjs/dist/viewer.css'
import mediaTypes from '@/variables/mediaTypes'

export default {
  name: 'DeviceMedias',
  components: { BSkeleton },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    device: String,
  },
  data() {
    return {
      search: {
        type: [],
      },
      mediaTypes,
      loading: true,
      items: [],
      itemsLoading: [0, 1, 2, 4],
      isShowItems: false,
      loadingItems: false,
      currentDate: { data: '' },
      medias: [],
      token: '',
      fields: [
        {
          key: 'created_at',
          label: 'Data',
          class: 'w-20',
          thClass: 'text-center',
        },
        {
          key: 'type',
          label: 'Tipo',
          class: 'w-20',
          thClass: 'text-center',
        },
        {
          key: 'data',
          label: 'Data',
          class: 'w-20',
          thClass: 'text-center',
        },
      ],
    }
  },
  watch: {
    device(val) {
      if (val) {
        this.getData()
      }
    },
  },
  mounted() {
    this.getData()
    this.token = window.sessionStorage.getItem('accessToken')
  },
  methods: {
    getData() {
      this.loading = true
      this.items = this.$http.get(`devices/${this.device}/media-resume`)
        .then(response => {
          this.items = response
        }).finally(() => {

          this.loading = false
        })
    },
    getMediasByDay(day) {
      this.isShowItems = true
      this.currentDate = day
      this.getItems()

    },
    showDates() {
      this.isShowItems = false
    },
    getItems(meta) {
      this.loadingItems = true
      const search = {
        types: this.search.type,
        date: this.currentDate.data,
      }
      this.$search.get(
        `devices/${this.device}/medias`,
        meta,
        search,
        '',
      )
        .then(response => {
          this.medias = response
        }).finally(() => {

          this.loadingItems = false
        })
    },
    getImageUrl(media) {
      return `${media.path}?token=${this.token}`
    },
  },
}
</script>

<style scoped>

</style>
