const PeriodList = [
  {
    value: 'provisioned',
    text: 'Provisionado',
  },
  {
    value: 'unprovisioned',
    text: 'Desprovisionado',
  },
]
export default PeriodList
