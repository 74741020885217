const deviceDetailMixin = {
  data() {
    return {
      currentDevice: '',
      showDevideDetails: false,
    }
  },
  methods: {
    onShowDeviceDetails(device) {
      this.currentDevice = device.uuid
      this.showDevideDetails = true
    },
    onCloseDeviceDetails() {
      this.currentDevice = ''
      this.showDevideDetails = false
    },
  },
}
export default deviceDetailMixin
