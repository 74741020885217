const mediaTypes = [
  {
    value: 'image',
    text: 'Imagem',
  },
  {
    value: 'audio',
    text: 'Audio',
  },
  {
    value: 'screenshot',
    text: 'Screenshot',
  },
]

export default mediaTypes
