<template>
  <s-modal
    v-model="showModal"
    :title="title"
    cancel-title="Fechar"
    no-close-on-backdrop
    ok-title="Fechar"
    size="lg"
    fullscreen
    scrollable
    modal-class="full-screen"
    ok-only
    @change="onHide"
  >
    <b-row style="">
      <b-col sm="12" md="12">
        <Grid
          :fields="fields"
          :items="device.locations"
          edit-button-label="edit"
          new-button-label="new"
          :show-search="false"
          :per_page="100"
          height="65vh"
          infinite-scroll
        >
          <template #cell(date)="data">
            {{ data.item.date | moment('DD/MM/YYYY HH:mm:ss') }}
          </template>
          <template #cell(link)="data">
            <a :href="data.item.url_location" target="_blank">Ver no Mapa</a>
          </template>
        </Grid>
      </b-col>
    </b-row>
  </s-modal>

</template>
<script>

import moment from 'moment-timezone'

export default {
  name: 'DeviceLocationHistory',
  components: {
  },
  props: {
    value: {
      type: String,
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: false,
      default: 'Historíco de Localização do Dispositivo',
    },
    show: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showModal: false,
      device: { serial: '', locations: [] },
      currentInformations: { informations: [] },
      urlTiles: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <b>DashboardPRO</b> ',
      centerMap: { lat: 0, lon: 0 },
      zoom: 8,
      fields: [
        {
          key: 'date',
          label: 'Data',
          class: 'w-15',
          thClass: 'text-center',
        },
        {
          key: 'latitude',
          label: 'Latitude',
          class: 'w-33',
          thClass: 'text-center',
        },
        {
          key: 'longitude',
          label: 'Longitude',
          class: 'w-33',
          thClass: 'text-center',
        },
        {
          key: 'link',
          label: 'Mapa',
          class: 'w-17',
          thClass: 'text-center',
        },
      ],
    }
  },
  watch: {
    show(value) {
      this.showModal = value
      if (value) {
        this.getDevice()
        setTimeout(() => { window.dispatchEvent(new Event('resize')) }, 250)
      }
    },
  },
  mounted() {
    this.getDevice()
    this.token = window.sessionStorage.getItem('accessToken')
    setTimeout(() => { window.dispatchEvent(new Event('resize')) }, 250)
  },
  methods: {
    moment,
    onHide() {
      this.$emit('onClose', true)
    },
    async getDevice() {
      if (this.value === '') {
        this.device = { serial: '', locations: [] }
        return
      }
      const response = await this.$http.get(`devices/${this.value}?include=locations`)
      if (response.error) {
        this.$notify.error(response.error_message)
        return
      }
      this.device = response
      if (this.device.locations.length > 0) {
        this.centerMap = this.device.locations[0].point
      }
    },
  },
}
</script>
